<template>
  <div class="warp">
    <div class="user">
      <img v-if="information" :src="information.headimgUrl ? information.headimgUrl : '@/assets/images/mrtx.png'" alt="">
      <p v-if="information">{{information.nickName}}</p>
    </div>
    <div class="main">
      <div class="itemList" v-for="(item, index) in itemList" :key="index" @click="itemClick(item)">
        <div class="left">
          <img :src="item.imgUrl" alt="">
          <span>{{item.name}}</span>
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <main-tab-bar-user></main-tab-bar-user>
  </div>
</template>

<script>
import MainTabBarUser from '@/components/assembly/Maintabbaruser.vue'
import { getInfo } from '@/api/notice.js'
export default {
  name: 'Home',
  components: {
    MainTabBarUser
  },
  data() {
    return {
      itemList: [
        {
          id: 1,
          imgUrl: require('@/assets/images/wddd.png'),
          name: '我的订单'
        },
        {
          id: 2,
          imgUrl: require('@/assets/images/wddd.png'),
          name: '我的去向单'
        },
        {
          id: 3,
          imgUrl: require('@/assets/images/wddd.png'),
          name: '添加去向单'
        }
      ],
      information: undefined
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() { // 获取信息个人
      getInfo().then(res => {
        if (res.data.code === 200) {
          this.information = res.data.data.wxUser
        }
      })
    },
    itemClick(item) { // 点击根据id跳转
      if (item.id === 1) {
        this.$router.push({path: 'Userorder'})
      } else if (item.id === 2) {
        this.$router.push({path: 'Destinationlist'})
      } else if (item.id === 3) {
        this.$router.push({path: 'Adddestinationlist'})
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.warp {
  .user {
    display: flex;
    align-items: center;
    padding: 20px 50px;
    background-color: #fff;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 15px;
    }
    p {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .main {
    background-color: #fff;
    margin-top: 10px;
    padding: 10px 20px;
    .itemList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      .left {
        display: flex;
        align-items: center;
        img {
          width: 25px;
          margin-right: 15px;
        }
      }
    }
  }
}
</style>